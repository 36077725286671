import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import LivePlayround from "~/components/LivePlayground/LivePlayground";
import Args from "~/components/Args/Args";
import AccessibilityTable from "~/components/AccessibilityTable/AccessibilityTable";
import ComponentStatusBar from "~/components/ComponentStatusBar/ComponentStatusBar";
import ComponentDescription from "~/components/ComponentDescription/ComponentDescription";
import ComponentImages from "~/components/ComponentImages/ComponentImages";
export const componentName = {
  display: "Details",
  code: "Details"
};
export const _frontmatter = {
  "menuLabel": "Details",
  "sortOrder": 4.2
};
const layoutProps = {
  componentName,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Details`}</h1>
    <ComponentDescription of={componentName.display} mdxType="ComponentDescription" />
    <ComponentStatusBar of={componentName.display} mdxType="ComponentStatusBar" />
    <hr></hr>
    <ComponentImages component={componentName} mdxType="ComponentImages" />
    <LivePlayround componentName={componentName} storybookLink="https://caesars-ui-storybook.pages.dev/?path=/story/components-Details--divider-default" mdxType="LivePlayround" />
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Details } from "@caesars-digital/caesars-ui"
`}</code></pre>
    <p><inlineCode parentName="p">{`Details`}</inlineCode>{` gives you a consistent way to display non-interactive text and graphic "tags" to support your UI.
An example of a good use of a Detail is the live score / info section of a Market Template.  None of these elements
on their own are clickable or interactive, they serve as information only.  `}</p>
    <p>{`The `}<inlineCode parentName="p">{`list`}</inlineCode>{` prop takes objects that conform to `}<inlineCode parentName="p">{`DetailType`}</inlineCode>{`.  These can also be referred to as "tags".  Each tag can
have text, a left icon, right icon, custom typography tokens, foreground and background colors giving you lots
of flexibility to create intersting tags. `}</p>
    <p>{`We've also compiled a list of presets, common tag looks and usages for example `}<inlineCode parentName="p">{`{ type: 'live' }`}</inlineCode>{` will give you
a tag that has the live icon animating, and the words "Live" next to it. `}</p>
    <hr></hr>
    <h2>{`Properties`}</h2>
    <div className="text-size-4 mb-10"><span className="text-red-600">*</span> indicates required.</div>
    <Args component={componentName} mdxType="Args" />
    <h2>{`Accessibility`}</h2>
    <AccessibilityTable className="mb-20" mdxType="AccessibilityTable">
  <AccessibilityTable.Principle principle="Percievable" status="ready">
    The Date Details component has a clear visual display that distinguishes the day of the week and start time from other content on the page. It utilizes appropriate color contrast and font size to ensure readability for all users.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Operable" status="ready">
    While the Date Details component may not have direct user interactivity, it is keyboard navigable, allowing users to access the information easily using keyboard navigation. It also provides focus indicators for keyboard users.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Understandable" status="ready">
    The purpose of the Date Details component is clear through descriptive labels or headings. Users can easily understand the displayed day of the week and start time for the match.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Robust" status="ready">
    The Date Details component is designed to be compatible with assistive technologies, ensuring that screen readers can access the information accurately. It functions consistently across various browsers and platforms.
  </AccessibilityTable.Principle>
    </AccessibilityTable>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      